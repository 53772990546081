<template lang="pug">
.container-fluid.layout-max-height-full.py-0.bg-warning.safe-area-inset-top
	header.text-center
		b-row.justify-content-center
			b-col.text-center(style="display: flex; justify-content: center;")
				img.d-sm-none.pb-4.mt-2(src="/img/bookli-logo.svg" style="width: 50%; max-width: 385px;")

	b-overlay.cards-container.scroll-container(
		:show="$apollo.queries.bookings.loading"
		variant="transparent"
		spinner-variant="primary")

		b-card.card-small-padding.cursor-pointer(
			body-class="p-0"
			v-for="booking in todayBookings"
			:key="booking.id")
			ResourceBookingCard(
				v-if="booking.__typename === 'ResourceBooking'"
				:resourceBookingId="booking.id"
				timeType="startTime")

			WorkshopBookingCard(
				v-else-if="booking.__typename === 'WorkshopBooking'"
				:workshopBookingId="booking.id"
				:selectedDayId="booking.workshop.dayId"
				timeType="startTime")

	b-card.card-small-padding.card-no-border.bg-events(
		v-if="!todayBookings.length"
		style="width: 100%")
		p.mb-2 {{ $t('pages.user.calendar.noBookings.title') }}
		small {{ $t('pages.user.calendar.noBookings.description') }} <b> {{ selectedDate | moment('D MMMM') }}</b>
		b-button.mt-4(
			variant="outline-primary"
			@click="$router.push({ name: 'User.ResourceBooking.Create', query: { date: selectedDate.toISOString() } })"
			block) {{ $t('pages.user.calendar.noBookings.createBooking') }}


	.card-white-bg.card-white-full.py-4.card-white-bottom
		v-date-picker(
			ref="calendar"
			v-model="selectedDate"
			mode='single'
			is-inline
			:attributes="calendarEvents"
			:is-required="true"
			:from-page.sync="fromPage")

</template>

<script lang="ts">
// @ts-ignore
import Calendar from 'v-calendar/lib/components/calendar.umd'
// @ts-ignore
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { GetBookingsForUserCalendarQuery, GetBookingsForUserCalendar, GetBookingsForUserCalendarQueryVariables } from '@/graphql'
import WorkshopBookingCard from '@/components/app/workshopBookingCard.vue'
import ResourceBookingCard from '@/components/app/resourceBookingCard.vue'
// @ts-ignore
import VCalendar from 'v-calendar'
Vue.use(VCalendar)

@Component({
	components: {
		Calendar,
		DatePicker,
		WorkshopBookingCard,
		ResourceBookingCard
	},
	apollo: {
		bookings: {
			query: GetBookingsForUserCalendar,
			variables(): GetBookingsForUserCalendarQueryVariables {
				return {
					filtering: {
						startDate: this.startDate,
						endDate: this.endDate
					}
				}
			},
			skip() {
				if (!this.startDate || !this.endDate) return true
				return false
			},
			fetchPolicy: 'no-cache'
		}
	}
})
export default class CompanyUsersUserView extends Vue {
	bookings: GetBookingsForUserCalendarQuery['bookings'] = []

	themeStyles = {
		titlePosition: 'left'
	}

	fromPage = {
		month: 0,
		year: 0
	}

	startDate: null | string = null
	endDate: null | string = null

	@Watch('fromPage')
	monthChange() {
		const date = this.$moment().set('year', this.fromPage.year).set('month', this.fromPage.month - 1)
		const startDate = date.clone().startOf('month').startOf('day')
		const endDate = date.clone().endOf('month').endOf('day')

		this.$apollo.queries.bookings.refetch()
		this.startDate = startDate.toISOString()
		this.endDate = endDate.toISOString()
	}

	@Watch('bookings')
	bookingsChanged() {
		this.calendarEvents = []
		this.bookings.forEach(booking => {
			if (booking.__typename === 'ResourceBooking') {
				this.calendarEvents.push({
					key: 'event-' + booking.id,
					dot: true,
					// @ts-ignore
					dates: this.$moment(booking.startDate).startOf('day').toDate(),
					customData: {
						// @ts-ignore
						isoDate: this.$moment(booking.startDate).startOf('day').toISOString(),
						bookingId: booking.id
					}
				})
			} else if (booking.__typename === 'WorkshopBooking') {
				booking.workshop.days.forEach(day => {
					this.calendarEvents.push({
						key: 'event-' + booking.id,
						dot: true,
						// @ts-ignore
						dates: this.$moment(day.startDate).startOf('day').toDate(),
						customData: {
							// @ts-ignore
							isoDate: this.$moment(day.startDate).startOf('day').toISOString()
						}
					})
				})
			}
		})
	}

	get todayBookings() {
		const events = this
			.calendarEvents
			.filter(event =>
				// @ts-ignore
				event.customData.isoDate === this.$moment(this.selectedDate).startOf('day').toISOString() &&
				event.key.includes('event')
			)

		const result = events.map(event => {
			const id = event.key.replace('event-', '')
			const booking = this.bookings.find(booking => booking.id === id)!

			let bookingResult = {
				...booking
			}

			if (bookingResult.__typename === 'WorkshopBooking') {
				const currentDate = this.$moment(this.selectedDate).format('l')
				//@ts-ignore
				const day = bookingResult.workshop.days.find(x => this.$moment(x.startDate).format('l') === currentDate)
				//@ts-ignore
				bookingResult.workshop.dayId = day!.id
				//@ts-ignore
				bookingResult.workshop.startDate = day!.startDate
				//@ts-ignore
				bookingResult.workshop.endDate = day!.endDate
			}

			return bookingResult
		})

		return result
	}

	selectedDate = new Date()

	calendarEvents: { key: string, dot: boolean | string, dates: Date | Date[] | string, customData?: Object }[] = []
}
</script>

<style lang="sass" scoped>

</style>
